import * as React from "react"
import Layout from '../components/Layout'
import Banner from '../components/Banner'
import SectionWrapper from '../components/General/SectionWrapper'
import { Grid, Typography } from '@material-ui/core'
import PrimaryButton from '../components/General/Buttons/Primary'
import { graphql } from 'gatsby'

const NotFoundPage = ({ data }) => {
  const bannerImageData = data.bannerImage.edges[0].node
  return (
    <Layout pageSeo={{ title: "Page Not Found | Vybinex", description: "" }}>
      <Banner
        bannerId="404-banner"
        heading="Error 404"
        imageComponent={bannerImageData} />
      <SectionWrapper>
        <Grid container direction='column' alignItems='center' spacing={2}>
          <Grid item>
            <Typography component='p' variant='h1' color='primary' >404</Typography>
          </Grid>
          <Grid item>
            <Typography component='p' variant='h5' color='primary' >Oops! Page not found</Typography>
          </Grid>
          <Grid item>
            <Typography component='p' variant='body1' color='primary' >Let's get you to where you need to be</Typography>
          </Grid>
          <Grid item>
            <PrimaryButton
              label="Go Home"
              buttonProps={{
                id: 'home-button',
                href: '/'
              }}
            />
          </Grid>
        </Grid>
      </SectionWrapper>
    </Layout>
  )
}


export const query = graphql`
query NotFoundPageQuery {
  bannerImage: allFile(
    filter: {sourceInstanceName: {eq: "images"}, name: {eq: "AboutBanner"}}
  ) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      }
    }
  }
}
`

export default NotFoundPage
